import React from 'react';
import Layout from 'components/Layout/Layout';
import { Helmet } from 'react-helmet';
import Favicon from '../assets/images/favicon-manatour-16x16.png';
import { withTrans } from '../i18n/withTrans';

const NotFoundPage = ({t}) => {
  return (
    <Layout>
      <Helmet>
        <title>404</title>
        <link rel="icon" type="image/png" href={Favicon} sizes="16x16" className="favicon" />
      </Helmet>
      <div style={{ padding: '50px 100px' }}>
        <h2>{t('404.title')}</h2>
        <p>{t('404.text')}</p>
      </div>
    </Layout>
  );
;}

export default withTrans(NotFoundPage);
